export const Enviroment = {

    url_apidian: 'https://apidian.adentlab.com.co:90/api/ubl2.1/',
    url_dtDental: 'https://test-api.dtdental.co/api/v1/',

    url_backend: 'https://servidor.adentlab.com.co:96/api/',
    url_socket: 'https://servidor.adentlab.com.co:96/',

    //url_backend: 'http://localhost:5005/api/',
   // url_socket: 'http://localhost:5005/',


    mapPK: 'pk.eyJ1IjoiYWRlbnRsYWJzb2x1Y2lvbiIsImEiOiJjbDBjNXR6aWMwaG1zM3BuYjd0eDYyOGw5In0.4mUuTY55LqyZNSC_u9A9KQ'

}